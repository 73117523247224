<script lang="ts" setup>
import { useIssue } from '~/composables/issue/useIssue'

const { issue } = useIssue()
</script>

<template>
  <UiPageBackground class="flex w-full flex-col items-center justify-center">
    <header class="flex w-full justify-center bg-white py-6">
      <div v-if="issue" class="w-[110px] shrink-0 lg:w-[140px]">
        <img
          :alt="issue.company.name"
          class="block h-auto w-full"
          loading="lazy"
          :src="issue.company.logo"
        >
      </div>
    </header>

    <div class="flex grow items-center justify-center">
      <div class="mx-auto w-full max-w-[600px] px-5 py-14">
        <slot />
      </div>
    </div>
  </UiPageBackground>
</template>
